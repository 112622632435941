import { useCallback, useEffect } from 'react'
import { useRevalidator } from '@remix-run/react'

import isBrowser from '~/core/generic/is-browser'
import useSupabase from '~/core/hooks/use-supabase'

const AuthRedirectListener: React.FCC<{
  whenSignedOut?: string
  accessToken: Maybe<string>
}> = ({ children, whenSignedOut, accessToken }) => {
  const client = useSupabase()
  const redirectUserAway = useRedirectUserAway()
  const { revalidate } = useRevalidator()

  useEffect(() => {
    // keep this running for the whole session
    // unless the component was unmounted, for example, on log-outs
    const listener = client.auth.onAuthStateChange((state, session) => {
      // log user out if user is falsy
      // and if the consumer provided a route to redirect the user
      const shouldLogOut = !session && whenSignedOut

      if (shouldLogOut) {
        return redirectUserAway(whenSignedOut)
      }

      // if the server has a token, we compare it to the client's token
      const isTokenOutOfSync = session?.access_token !== accessToken

      // server and client are out of sync.
      // Remix recalls active loaders after actions complete
      if (isTokenOutOfSync) {
        revalidate()
      }
    })

    // destroy listener on un-mounts
    return () => listener.data.subscription.unsubscribe()
  }, [client.auth, redirectUserAway, whenSignedOut, revalidate, accessToken])

  return <>{children}</>
}

export default function AuthChangeListener({
  children,
  accessToken,
  whenSignedOut,
}: React.PropsWithChildren<{
  accessToken: Maybe<string>
  whenSignedOut?: string
}>) {
  const shouldActivateListener = isBrowser()

  // we only activate the listener if
  // we are rendering in the browser
  if (!shouldActivateListener) {
    return <>{children}</>
  }

  return (
    <AuthRedirectListener
      accessToken={accessToken}
      whenSignedOut={whenSignedOut}
    >
      {children}
    </AuthRedirectListener>
  )
}

function useRedirectUserAway() {
  return useCallback((path: string) => {
    const currentPath = window.location.pathname
    const isNotCurrentPage = currentPath !== path

    // we then redirect the user to the page
    // specified in the props of the component
    if (isNotCurrentPage) {
      window.location.assign(path)
    }
  }, [])
}
